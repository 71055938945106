@import '../helpers/fonts';

// variaveis
@import "../helpers/variables.scss";

// mixins
@import "../helpers/mixins";

// functions
@import "../helpers/functions";

// placeholders
@import "../helpers/placeholders";

.aside {
  // @media (min-width: 960px) {
  //   display: none !important;
  // }
  .theme--dark.v-sheet {
    background: transparent !important;
  }

  &-menu {
    &-busca {
      margin-top: 0;
      padding-top: 0;
      .v-input__prepend-outer {
        position: absolute;
        z-index: 1;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 !important;
      }

      .v-input__slot {
        margin: 0 !important;
        padding-left: 40px !important;
        min-height: 40px !important;
      }

      &.v-text-field--solo .v-input__control {
        min-height: 40px;
      }

      .v-text-field__details {
        display: none;
      }
    }

    &-list {
      &-group {
        &--link {
          font-size: 0.8em;
          padding-left: 20px;
        }

        &--usuario {
          .v-list-item__icon:last-of-type:not(:only-child) {
            margin-left: 0;
            min-width: auto;
          }

          &-avatar {
            margin-right: 15px !important;
          }

          &-titulo {
            font-size: 0.8em;
            font-weight: bold;
            text-transform: capitalize;
          }

          .v-list-item__title {
            text-transform: capitalize;
          }

          &-item {
            max-width: 200px;
            padding-left: 0;
          }
        }
      }
    }

    &-mobile {
      @media (min-width: 640px) {
        display: none;
      }
    }
  }

  .v-list-group__header__prepend-icon {
    display: none;
  }
  .v-icon {
    font-size: 24px !important;
  }

  .v-btn__content {
    color: $colorPrimary;
  }

  .icon-close-mobile {
    @media (min-width: 1264px) {
      display: none;
    }
  }
}

.v-list-item--dense .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list--dense .v-list-item .v-list-item__subtitle {
  text-transform: capitalize;;
}